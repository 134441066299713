
import { computed, defineComponent, PropType } from "vue";
import { isNull } from "lodash";

import { getItemTypeBackground } from "@/utils/itemTypes";
import { TimesheetType } from "@/types/Timesheet";
import { useItemTypes } from "@/store/itemTypes.store";

export default defineComponent({
  name: "PCalendarType",

  props: {
    data: { type: Object as PropType<TimesheetType>, required: true }
  },

  setup(props) {
    const itemTypes = useItemTypes();

    const present = computed(() => {
      return props.data.present;
    });

    const missing = computed(() => {
      return !props.data.present && isNull(props.data.timeSheetItemTypeId);
    });

    const background = computed(() => {
      if (present.value) {
        return getItemTypeBackground("ЗАРЕГИСТРИРОВАН");
      } else if (!present.value && missing.value) {
        return getItemTypeBackground("БЕЗ ПРИЧИНЫ");
      } else {
        const name = itemTypes.getName(props.data.timeSheetItemTypeId!);
        return getItemTypeBackground(name);
      }
    });

    return {
      props,
      background,
      present
    };
  }
});
