import { reactive, toRefs } from "vue";

import { ItemType } from "@/types/Timesheet";
import { timesheetService } from "@/services/timesheet.service";
import { find } from "lodash";

interface IItemTypesState {
  itemTypes: ItemType[] | [];
  loading: boolean;
  error: boolean;
}

const state = reactive<IItemTypesState>({
  itemTypes: [],
  loading: false,
  error: false
});

export function useItemTypes() {
  async function loadItemTypes(userId: number | string) {
    try {
      state.loading = true;
      state.error = false;
      state.itemTypes = [];

      state.itemTypes = await timesheetService.getItemTypes(userId);
    } catch {
      state.error = false;
    } finally {
      state.loading = false;
    }
  }

  function getName(typeId: number) {
    return find(state.itemTypes, ["id", typeId])?.name;
  }

  return {
    ...toRefs(state),
    loadItemTypes,
    getName
  };
}
