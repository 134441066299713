
import { defineComponent } from "vue";
import { weekdays } from "@/utils";

export default defineComponent({
  name: "PWeekdays",

  setup() {
    return { weekdays };
  }
});
