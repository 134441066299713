import { AxiosInstance } from "axios";

import { api } from "@/services/api.service";
import { ItemType, Timesheet } from "@/types/Timesheet";

class TimesheetService {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public getItemTypes(userId: number | string): Promise<ItemType[]> {
    return this.api.get(`/area/user/${userId}/time-sheet/item-type`);
  }

  public getTimesheet(
    userId: number | string,
    date: string
  ): Promise<Timesheet> {
    return this.api.get(`/area/user/${userId}/time-sheet/${date}`);
  }
}

export const timesheetService = new TimesheetService(api);
