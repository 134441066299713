
import { computed, defineComponent, ref, watch } from "vue";
import { upperFirst } from "lodash";

import { months } from "@/utils";
import PCard from "@/components/PCard.vue";

export default defineComponent({
  name: "PCalendarDatepicker",
  components: { PCard },
  props: { modelValue: Date },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const screen = ref("none");
    const month = ref(0);
    const year = ref(0);
    const date = ref(new Date());

    const stringDate = computed(() => {
      // @ts-ignore
      const string = props.modelValue.toLocaleDateString("ru-RU", {
        month: "long",
        year: "numeric"
      });

      return upperFirst(string);
    });

    const years = computed(() => {
      const years = [];
      // @ts-ignore
      const currentYear = props.modelValue.getFullYear();

      for (let y = currentYear - 4; y <= currentYear + 7; y++) {
        years.push(y);
      }

      return years;
    });

    const selectScreen = (s: string) => {
      if (screen.value === s) return (screen.value = "none");
      screen.value = s;
    };

    const selectYear = (y: number) => {
      year.value = y;
      selectScreen("month");
    };

    const selectMonth = (m: number) => {
      month.value = m;
      selectScreen("month");
      date.value = new Date(year.value, month.value, 1);
    };

    watch(
      () => date.value,
      () => {
        emit("update:modelValue", date.value);
      }
    );

    return {
      props,
      stringDate,
      screen,
      months,
      selectScreen,
      years,
      selectYear,
      selectMonth
    };
  }
});
