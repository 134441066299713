import { isUndefined } from "lodash";

const itemTypes = [
  { name: "ЗАРЕГИСТРИРОВАН", background: "bg-green-400" },
  { name: "БЕЗ ПРИЧИНЫ", background: "bg-red-400" },
  { name: "ОРВИ", background: "bg-blue-400" },
  { name: "ГРИПП", background: "bg-yellow-500" },
  { name: "ДР. БОЛЕЗНИ", background: "bg-gray-600" },
  { name: "ОТПУСК", background: "bg-purple-400" }
];

export function getItemTypeBackground(name: string | undefined) {
  return (
    itemTypes.find(item => item.name === name?.trim().toUpperCase()) || {
      background: "bg-gray-300"
    }
  ).background;
}
