import { reactive, toRefs } from "vue";

import { timesheetService } from "@/services/timesheet.service";
import { Timesheet } from "@/types/Timesheet";
import { dateToString } from "@/utils";

interface ITimesheetState {
  timesheet: Timesheet | null;
  loading: boolean;
  error: boolean;
}

const state = reactive<ITimesheetState>({
  timesheet: null,
  loading: false,
  error: false
});

export function useTimesheet() {
  async function loadTimesheet(userId: number | string, date: string) {
    try {
      state.loading = true;
      state.error = false;
      state.timesheet = null;

      state.timesheet = await timesheetService.getTimesheet(userId, date);
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  function getSheetByDate(date: Date) {
    const dateString = dateToString(date);
    return state.timesheet?.items.timeSheet[dateString];
  }

  function isFreeDay(date: Date | string) {
    const dateString = typeof date === "string" ? date : dateToString(date);
    return state.timesheet?.freeDays.includes(dateString);
  }

  return {
    ...toRefs(state),
    loadTimesheet,
    getSheetByDate,
    isFreeDay
  };
}
