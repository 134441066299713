
import { computed, defineComponent, PropType } from "vue";
import { useRoute } from "vue-router";
import { upperFirst } from "lodash";

import { useTimesheet } from "@/store/timesheet.store";
import { dateToString, weekdays } from "@/utils";
import { getCalendarMatrix } from "@/utils/calendarMatrix";
import { getItemTypeBackground } from "@/utils/itemTypes";

import PCard from "@/components/PCard.vue";
import PCalendarType from "@/components/calendar/PCalendarType.vue";
import PCalendarFreeDay from "@/components/calendar/PCalendarFreeDay.vue";
import PCalendarDatepicker from "@/components/calendar/PCalendarDatepicker.vue";
import PWeek from "@/components/calendar/PWeek.vue";
import PDay from "@/components/calendar/PDay.vue";
import PWeekdays from "@/components/calendar/PWeekdays.vue";
import { useItemTypes } from "@/store/itemTypes.store";
import UiSkeletonDay from "@/components/ui/skeletons/UiSkeletonDay.vue";
import UiSkeletonText from "@/components/ui/skeletons/UiSkeletonText.vue";

export default defineComponent({
  name: "PCalendar",
  components: {
    UiSkeletonText,
    UiSkeletonDay,
    PWeekdays,
    PDay,
    PWeek,
    PCalendarDatepicker,
    PCalendarFreeDay,
    PCalendarType,
    PCard
  },

  props: {
    flat: { type: Boolean },
    to: { type: Object },
    date: { type: Object as PropType<Date>, default: () => new Date() }
  },

  setup(props) {
    const route = useRoute();

    const timesheetStore = useTimesheet();
    const itemTypes = useItemTypes();

    const matrix = computed(() => {
      return getCalendarMatrix({
        year: props.date.getFullYear(),
        mount: props.date.getMonth()
      });
    });

    const dateString = computed(() => {
      return dateToString(props.date);
    });

    const dateText = computed(() => {
      const string = props.date.toLocaleDateString("ru-RU", {
        month: "long",
        year: "numeric"
      });

      return upperFirst(string);
    });

    return {
      props,
      route,
      dateString,
      dateText,
      matrix,
      weekdays,
      timesheetStore,
      loading: timesheetStore.loading,
      itemTypes: itemTypes.itemTypes,
      getItemTypeBackground
    };
  }
});
