import eachDayOfInterval from "date-fns/eachDayOfInterval";
import endOfISOWeek from "date-fns/endOfISOWeek";
import endOfMonth from "date-fns/endOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import startOfISOWeek from "date-fns/startOfISOWeek";
import startOfMonth from "date-fns/startOfMonth";
import eachWeekOfInterval from "date-fns/eachWeekOfInterval";

interface IOptions {
  year: number;
  mount: number;
}

type convertFn = (date: Date, { isSameMonth }: { isSameMonth: boolean }) => any;

export function getCalendarMatrix(
  { year, mount }: IOptions,
  convertDate: convertFn = date => date
) {
  const date = new Date(year, mount);

  const matrix = eachWeekOfInterval(
    {
      start: startOfMonth(date),
      end: endOfMonth(date)
    },
    { weekStartsOn: 1 }
  );

  return matrix.map(weekDay =>
    eachDayOfInterval({
      start: startOfISOWeek(weekDay),
      end: endOfISOWeek(weekDay)
    }).map(day =>
      convertDate(day, {
        isSameMonth: isSameMonth(date, day)
      })
    )
  );
}
